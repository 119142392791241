/**
 * Order is very important.
 * 1st: SCSS variable overrides
 * 2nd: build import
 * 3rd: component hooks
 * 4th: component import
 * 5th: CSS variables declaration/override (build includes :root level declaration)
 * 6th: other custom CSS
 */

// font
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// 1st: SCSS variable overrides
$global-prefix : '';


// 2nd: build import
@import '../../../../../../otherSrc/client/we/scss/build';

// our custom component // * NOTE: we can not include partial SCSS files inside the client folder, atm.

// 3rd: component hooks
@mixin hook-button {

}

// 4th: component import
@import '../../../../../../otherSrc/client/we/scss/components';

// 5th custom CSS


// 6th: CSS variables declaration/override
:root {
    --c-core-lead              : #0865ac;
    --c-core-lead-2            : #00a9cd;
    --c-core-alt               : #0ca47f;
    --c-core-alt-2             : #22bC97;
  //  --c-core-inverse          : #222222;
   // --c-core-bg-4             : #faf9f6;
   // --c-core-bg-5             : #edece9;
   // --c-core-bg               : #ffffff;
  //  --c-text                  : #2a2a2a;
    --c-comp-lead             : #6fab22;///
    --c-comp-lead-2           : #89c53c;///
    --c-comp-alt              : #ffee13;
    --c-comp-alt-2            : #ffff2d;
  //  --c-gray-1                : #f2f2f2;
   // --c-gray-2                : #d1d8e0;
  //  --c-gray                  : #a5b1c2;
 //   --c-gray-3                : var(--c-gray);
  //  --c-gray-4                : #778ca3;
  //  --c-gray-5                : #393946;
  //  --c-success               : var(--c-core-lead);
  //  --c-success-2             : var(--c-core-lead-2);
    --c-success-inverse       : var(--c-bg);
    --c-warning               : #ee8735;
    --c-warning-inverse       : var(--c-bg);
    --c-danger                : #eb3b5a;
    --c-danger-inverse        : var(--c-bg);
    --c-error                 : var(--c-danger);
    --c-error-inverse         : var(--c-danger-inverse);
}

// 6th: other custom CSS - generic page stuff
*,
*::before,
*::after {
    box-sizing: border-box;
}

html, body  {
    background: var(--c-bg);

    &[lang=he] * {
        font-family: 'Rubik', 'Assistant', -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
    }

}
